import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import config from '../config.json';
import Headers from "../bin/Headers";
import compileSystemTicker from "../bin/compileSystemTicker";
import signedCurrency from "../bin/signedCurrency";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import dontRerender from "../bin/dontRerender";
import _logout from "../bin/logout";
import LOADING from './LOADING';
import { BookOpenIcon, CheckCircleIcon, XCircleIcon, ClockIcon } from "@heroicons/react/24/solid";
import decodeJwt from "../bin/decodeJwt";
import unauthorizedLevel from "../bin/unauthorizedLevel";

const MAIN = (props) => {
	let token = useSelector(state => state.token),
		[loading, setLoading] = useState(false),
		[balance, setBalance] = useState(0),
		[currentReturn, setCurrentReturn] = useState(0),
		[trades, setTrades] = useState([]),
		[orders, setOrders] = useState([]),
		tickers = useRef(null),
		[latest, setLatest] = useState({}),
		[totals, setTotals] = useState({}),
		positions = useRef(null),
		refreshTimer = useRef(null),
		[tickerData, setTickerData] = useState([]),
		decodedToken = decodeJwt(token);

	const readPositions = async (showLoading) => {
		if (showLoading) { setLoading(true); }
		let _positions = await fetch(`${config.api}/robinhoodpositions`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.optionPositions ? d.optionPositions : []);
		if (_positions && _positions.length) {
			let _tickers = {};
			_positions = _positions.map(entry => {
				let UnderlyingTicker = entry.symbol ? entry.symbol : null;
				let OptionExpiration = entry.legs && entry.legs[0] && entry.legs[0].expiration_date ? entry.legs[0].expiration_date : null;
				let OptionType = entry.legs && entry.legs[0] && entry.legs[0].option_type ? (entry.legs[0].option_type).toUpperCase() : null;
				let OptionStrike = entry.legs && entry.legs[0] && entry.legs[0].strike_price && !isNaN(entry.legs[0].strike_price) ? Number(entry.legs[0].strike_price) : null;
				let SystemTicker = compileSystemTicker(UnderlyingTicker, OptionExpiration, OptionType, OptionStrike);
				if (!_tickers.hasOwnProperty(SystemTicker)) { _tickers[SystemTicker] = true; }
				return {
					UnderlyingTicker,
					Size: entry.quantity && !isNaN(entry.quantity) ? Number(entry.quantity) : null,
					AssetType: "OPTION",
					OptionExpiration,
					OptionType,
					OptionStrike,
					SharesPerContract: 100,
					Cost: entry.quantity && !isNaN(entry.quantity) && entry.average_open_price && !isNaN(entry.average_open_price) ? truncateToTwoDigits(Number(entry.quantity) * Number(entry.average_open_price)) : null,
					EntryPrice: entry.average_open_price && !isNaN(entry.average_open_price) ? truncateToTwoDigits((Number(entry.average_open_price) / 100), 4) : null,
					ExitPrice: 0,
					StopPrice: 0,
					Commission: 0,
					Return: 0,
					ReturnPercent: 0,
					ReturnOnCapital: 0,
					SystemTicker,
				};
			});
			setTrades(_positions);
			positions.current = _positions;
			if (_tickers && Object.keys(_tickers)) {
				tickers.current = _tickers;
			}
			await loadLatest();
		}
		if (showLoading) { setLoading(false); }
	};

	const readBalance = async () => {
		let _balance = await fetch(`${config.api}/robinhoodbalance`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.hasOwnProperty('cashBalance') && d.hasOwnProperty('currentReturn') ? d : null);
		if (_balance.cashBalance) { setBalance(_balance.cashBalance); }
		if (_balance.currentReturn) { setCurrentReturn(_balance.currentReturn); }
	};

	const readOrders = async () => {
		let _orders = await fetch(`${config.api}/robinhoodoptionorders`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.pendingOrders ? d.pendingOrders : []);
		if (_orders && Array.isArray(_orders)) { setOrders(_orders); }
	};

	const loadLatest = async () => {
		if (!tickers.current || !Object.keys(tickers.current).length) { return; }
		let totalCost = 0;
		let totalBid = 0;
		let totalMid = 0;
		let totalAsk = 0;
		let _tickers = Object.keys(tickers.current).join(',');
		let _latest = await fetch(`${config.api}/latest?tickers=${_tickers}`, { headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
		if (_latest && _latest.length) {
			_latest = _latest.filter(({ Ticker }) => Ticker).reduce((currentMap, entry) => { currentMap[entry.Ticker] = entry; return currentMap; }, {});
		}
		for (let entry of positions.current) {
			if (!_latest[entry.SystemTicker]) { continue; }
			totalCost += truncateToTwoDigits(entry.Cost);
			totalBid += truncateToTwoDigits(_latest[entry.SystemTicker].Bid * entry.SharesPerContract * entry.Size);
			totalMid += truncateToTwoDigits(_latest[entry.SystemTicker].Mid * entry.SharesPerContract * entry.Size);
			totalAsk += truncateToTwoDigits(_latest[entry.SystemTicker].Ask * entry.SharesPerContract * entry.Size);
		}
		// console.log(_latest);
		let _bid = truncateToTwoDigits((totalCost * -1) + totalBid);
		let _mid = truncateToTwoDigits((totalCost * -1) + totalMid);
		let _ask = truncateToTwoDigits((totalCost * -1) + totalAsk);
		let _cost = truncateToTwoDigits(totalCost * -1);
		totalBid = signedCurrency(_bid.toLocaleString(), '$');
		totalMid = signedCurrency(_mid.toLocaleString(), '$');
		totalAsk = signedCurrency(_ask.toLocaleString(), '$');
		totalCost = signedCurrency(_cost.toLocaleString(), '$');
		setTotals({
			_cost,
			_bid,
			_mid,
			_ask,
			cost: totalCost,
			bid: totalBid,
			mid: totalMid,
			ask: totalAsk,
		})
		setLatest(_latest);
	};

	const calculateReturnUi = (entry, latest) => {
		if (!entry.Cost) { return ''; }
		let currentValue = Math.trunc(entry.Size * latest.SharesPerContract * latest.Bid);
		let roi = currentValue + (entry.Cost * -1);
		let roiPercent = truncateToTwoDigits(((currentValue / entry.Cost) - 1) * 100);
		roiPercent = roiPercent ? `${roiPercent}%` : '';
		return <span>
			<span className="mx-1 rounded px-1 text-amber-400 bg-amber-950">Value: ${currentValue}</span>
			<span className={`mx-1 rounded px-1 ${roi > 0 ? 'text-emerald-400 bg-emerald-950' : ''}${roi < 0 ? 'text-rose-400 bg-rose-950' : ''}${roi === 0 ? 'text-gray-400 bg-gray-950' : ''}`}>Return: {signedCurrency(truncateToTwoDigits(roi), '$')}</span>
			<span className={`mx-1 rounded px-1 ${roi > 0 ? 'text-emerald-400 bg-emerald-950' : ''}${roi < 0 ? 'text-rose-400 bg-rose-950' : ''}${roi === 0 ? 'text-gray-400 bg-gray-950' : ''}`}>{roiPercent}</span>
		</span>;
	};

	const cancelOrder = async (order) => {
		// let confirmed = window.confirm(`Are you sure you want to cancel this order?`);
		// if (!confirmed) { return; }
		if (!order || !order.cancel_url) { window.alert('missing order or cancel url'); return; }
		let cancelUrl = order.cancel_url;
		let deleteOrder = await fetch(`${config.api}/robinhoodorder`, { headers: Headers(token), method: "DELETE", body: JSON.stringify({ cancelUrl }) }).then(d => d.json());
		readOrders();
		if (deleteOrder && deleteOrder.message) {
			return window.alert(deleteOrder.message);
		}
	};

	const exitOrder = async (trade, percent, quantity) => {
		//calculate the exit price (trade.EntryPrice * (1 + percent))
		let exitPrice = truncateToTwoDigits(trade.EntryPrice * (1 + (percent / 100)));
		quantity = quantity ? quantity : trade.Size;
		let payload = {
			trade: {
				optionExpiration: trade.OptionExpiration,
				optionStrike: trade.OptionStrike,
				optionType: trade.OptionType,
				underlyingTicker: trade.UnderlyingTicker,
				quantity,
				price: exitPrice,
			}
		};
		// console.log(payload);
		let sendExit = await fetch(`${config.api}/robinhoodorderexit`, { headers: Headers(token), method: "POST", body: JSON.stringify(payload) }).then(d => d.json());
		if (sendExit && sendExit.sellOrderId) {
			// readOrders();
			return window.alert(`exit order sent [${sendExit.sellOrderId}]`);
			// return;
		}
		if (sendExit && sendExit.message) {
			return window.alert(sendExit.message);
		}
	};

	const closeTrade = async (trade) => {
		let confirmed = window.confirm(`Are you sure you want to immediately close this position (continually try)?`);
		if (!confirmed) { return; }
		let payload = {
			trade: {
				optionExpiration: trade.OptionExpiration,
				optionStrike: trade.OptionStrike,
				optionType: trade.OptionType,
				underlyingTicker: trade.UnderlyingTicker,
				attemptOnce: false,
			}
		};
		// console.log("payload: ", payload);
		let sendExit = await fetch(`${config.api}/robinhoodorderexitimmediate`, { headers: Headers(token), method: "POST", body: JSON.stringify(payload) }).then(d => d.json());
		// console.log("sendExit: ", sendExit);
		// alert(sendExit.message);
		if (sendExit && sendExit.message) {
			alert(sendExit.message);
		} else if (sendExit && sendExit.sellOrderId) {
			readOrders();
			readBalance();
			readPositions();
			// alert(`Position exited successfully`);
			// clearInterval(refreshTimer.current);
			// start();
		}
	};

	const closeTradeAtLast = async (trade) => {
		let last = latest && trade && trade.SystemTicker && latest[trade.SystemTicker] && latest[trade.SystemTicker].Last ? latest[trade.SystemTicker].Last : null;
		if (!last) { return window.alert(`Could not determine last traded price`); }
		let payload = {
			trade: {
				optionExpiration: trade.OptionExpiration,
				optionStrike: trade.OptionStrike,
				optionType: trade.OptionType,
				underlyingTicker: trade.UnderlyingTicker,
				quantity: trade.Size,
				price: last,
			}
		};
		let sendExit = await fetch(`${config.api}/robinhoodorderexit`, { headers: Headers(token), method: "POST", body: JSON.stringify(payload) }).then(d => d.json());
		if (sendExit && sendExit.sellOrderId) {
			// readOrders();
			// return window.alert(`Exit order sent successfully`);
			return;
		}
		if (sendExit && sendExit.message) {
			return window.alert(sendExit.message);
		}
	};
	
	const closeTradeAtLastMatchDescension = async (trade) => {
		let confirmed = window.confirm(`Are you sure you want to immediately close this position (continually try)?`);
		if (!confirmed) { return; }
		let payload = {
			trade: {
				optionExpiration: trade.OptionExpiration,
				optionStrike: trade.OptionStrike,
				optionType: trade.OptionType,
				underlyingTicker: trade.UnderlyingTicker,
				priceMethod: 'matchLastDescension',
			}
		};
		let sendExit = await fetch(`${config.api}/robinhoodorderexitimmediatev1`, { headers: Headers(token), method: "POST", body: JSON.stringify(payload) }).then(d => d.json());
		if (sendExit && sendExit.message) {
			alert(sendExit.message);
		} else if (sendExit && sendExit.sellOrderId) {
			window.alert(`exit order sent [${sendExit.sellOrderId}]`);
			readOrders();
			readBalance();
			readPositions();
		}
	};
	
	const start = async () => {
		if (!token || !decodedToken || !decodedToken.level || unauthorizedLevel(decodedToken.level, 'diamond')) {
			window.location.href = '/notfound';
			return;
		}
		await Promise.all([readBalance(), readPositions(true), readOrders()]);
    return;
	};
	
	// useEffect(() => { start(); }, []);
	useEffect(() => {
		start();
		refreshTimer.current = setInterval(() => {
			loadLatest();
			readOrders();
			readBalance();
			readPositions();
		}, 1000);
		return () => {
			if (refreshTimer.current) {
				clearInterval(refreshTimer.current);
			}
		};
	}, []);
	
	return (
		<div className={`py-4 text-white w-full`}>

			<div className="flex md:flex-row gap-2 md:gap-5 justify-center items-start w-full">
			{loading ? <div className="w-full sm:w-[320px] space-y-5"><LOADING /></div>  : <></>}
			{!loading ?
				<div className="flex flex-col gap-5 flex-grow w-full items-start justify-center">
				
					<h1 className="text-2xl sm:text-3xl font-bold text-center w-full flex flex-col items-start justify-between">
						{currentReturn ? <span className='w-full flex flex-row items-start justify-between'>
								<span>Current Return:</span>
								<span className='flex flex-col items-end'>
									<span className={currentReturn && !isNaN(currentReturn) ? (Number(currentReturn)>0 ? `text-emerald-500` : (Number(currentReturn)<0 ? `text-rose-500` : ``)) : ``}>{currentReturn && !isNaN(currentReturn) ? signedCurrency(currentReturn.toLocaleString(), '$') : currentReturn}</span>
								</span>
							</span>
						: <></>}
						
						<span className='text-sm w-full flex flex-row items-start justify-between'>
							<span>Balance:</span>
							<span className='flex flex-col items-end'>
								<span>${balance && !isNaN(balance) ? balance.toLocaleString() : balance}</span>
							</span>
						</span>
					</h1>
					
					<div className='overflow-y-auto w-full'>
						<table className="divide-y divide-gray-400 w-full self-start text-xs sm:text-md overflow-x-auto whitespace-nowrap">
							
							<thead>
								<tr className="divide-x divide-gray-400 bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
									<th scope="col" className="p-1 font-medium uppercase tracking-wider w-36">Position</th>
									<th scope="col" className="p-1 font-medium uppercase tracking-wider w-5">
										<span className='text-amber-500'><BookOpenIcon className='w-5' /></span>
									</th>
								</tr>
							</thead>
							
							<tbody className='divide-y divide-gray-800'>
								{ (!trades || !trades.length) &&
									<tr><th scope="col" colSpan="6" className="py-8 font-light text-center text-gray-300 text-xs sm:text-md">no positions</th></tr>
								}
								{ trades && trades.length>0 && trades.map( (entry,idx) =>
									<tr key={idx} className={`divide-x divide-gray-800 bg-opacity-25 text-left ${idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'} text-xxs sm:text-sm text-gray-300`}>
										<td className="p-1 font-light align-top w-36">
											<span className='font-bold'>{ entry.UnderlyingTicker }</span> - <span className={`${ entry.OptionType==='PUT' ? 'text-red-700' : '' }${ entry.OptionType==='CALL' ? 'text-green-700' : '' }`}>{ entry.OptionType }</span> - <span className='text-amber-600'>${ Number((entry.OptionStrike).toFixed(2)).toFixed(2).toLocaleString() }</span><span className='mx-1 text-sky-400 bg-sky-950 rounded px-1'>Expires: { entry.OptionExpiration ? entry.OptionExpiration : '' }</span><br/>
											<span className='w-full flex flex-col items-start justify-start'>
												<span className='flex flex-row items-center justify-start'>
													{(entry.Size).toLocaleString()} @ ${(entry.EntryPrice).toLocaleString()}<span className='mx-1 text-rose-400 bg-rose-950 rounded px-1'>Cost: {entry.Cost ? `$${(entry.Cost).toLocaleString()}` : ''}</span>
												</span>
												<span className='flex flex-row flex-grow items-center justify-end'>
													{entry.Cost && latest && latest[entry.SystemTicker] && latest[entry.SystemTicker].Bid && entry.Size ? calculateReturnUi(entry, latest[entry.SystemTicker]) : ''}
												</span>
											</span>
											<span className='flex flex-row items-center justify-evenly'>
												<span className='text-emerald-400 bg-emerald-950 rounded px-1'>Bid: {latest && latest[entry.SystemTicker] && latest[entry.SystemTicker].Bid ? latest[entry.SystemTicker].Bid : ''}</span>
												<span className='text-rose-400 bg-rose-950 rounded px-1'>Ask: {latest && latest[entry.SystemTicker] && latest[entry.SystemTicker].Ask ? latest[entry.SystemTicker].Ask : ''}</span>
												<span className='text-sky-400 bg-sky-950 rounded px-1'>Last: {latest && latest[entry.SystemTicker] && latest[entry.SystemTicker].Last ? latest[entry.SystemTicker].Last : ''}</span>
											</span>
										</td>
										<td className="p-1 font-light inline-flex w-full h-full items-end justify-center flex-col gap-1">
											{/* <button onClick={() => { exitOrder(entry, 100, (Math.trunc(entry.Size * 0.5))); }} className='w-[120px] text-[10px] text-black bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell 50% ({Math.trunc(entry.Size * 0.5)}) @ 100%</button> */}
											{/* <button onClick={() => { exitOrder(entry, 200, (Math.trunc(entry.Size * 0.25))); }} className='w-[120px] text-[10px] text-black bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell 25% ({Math.trunc(entry.Size * 0.25)}) @ 200%</button> */}
											<button onClick={() => { exitOrder(entry, 100, entry.Size); }} className='w-[120px] text-[10px] text-black bg-gradient-to-r from-green-500 via-lime-500 to-amber-500 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell 100% ({entry.Size}) @ 100%</button>
											{/* <button onClick={() => { exitOrder(entry, 300, entry.Size); }} className='w-[120px] text-[10px] text-black bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell 100% ({entry.Size}) @ 300%</button> */}

											{/* <button onClick={() => { exitOrder(entry, 50); }} className='w-[120px] text-sky-300 bg-sky-700 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell @ +50%</button> */}
											{/* <button onClick={() => { closeTradeAtLast(entry); }} className='w-[120px] text-purple-300 bg-purple-700 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell @ Last</button> */}
											{/* <button onClick={() => { closeTradeAtLast(entry); }} className='w-[120px] text-purple-300 bg-purple-700 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell @ Last</button> */}
											<button className={`w-[120px] h-full flex justify-between rounded px-3 py-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white border border-black`} onClick={() => { closeTradeAtLastMatchDescension(entry); }}>
												<span>💫</span>
												<span>Sell</span>
												<span>💫</span>
											</button>
											<button onClick={() => { closeTrade(entry); }} className='w-[120px] text-red-300 bg-red-700 px-1 py-0.5 rounded h-full flex items-center justify-center'>Sell @ Bid</button>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

				</div>
			: <></>}
			</div>

			{!loading ?
				<div className='mt-10 overflow-y-auto w-full'>
					<table className="divide-y divide-gray-400 w-full self-start text-xs sm:text-md overflow-x-auto whitespace-nowrap">
						
						<thead>
							<tr className="divide-x divide-gray-400 bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
								<th scope="col" className="p-1 font-medium uppercase tracking-wider w-36">Orders</th>
								<th scope="col" className="p-1 font-medium uppercase tracking-wider w-5"></th>
							</tr>
						</thead>
						
						<tbody className='divide-y divide-gray-800'>
							{ (!orders || !orders.length) &&
								<tr><th scope="col" colSpan="6" className="py-8 font-light text-center text-gray-300 text-xs sm:text-md">no orders</th></tr>
							}
							{ orders && orders.length>0 && orders.map( (entry,idx) =>
								<tr key={idx} className={`divide-x divide-gray-800 bg-opacity-25 text-left ${idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'} text-xxs sm:text-sm text-gray-300`}>
									<td className="p-1 font-light align-top w-36">
										<span className='font-bold'>{ entry.chain_symbol }</span> - <span className={`${ entry.legs && entry.legs[0] && entry.legs[0].option_type && entry.legs[0].option_type.toUpperCase()==='PUT' ? 'text-red-700' : '' }${ entry.legs && entry.legs[0] && entry.legs[0].option_type && entry.legs[0].option_type.toUpperCase()==='CALL' ? 'text-green-700' : '' }`}>{ entry.legs && entry.legs[0] && entry.legs[0].option_type ? entry.legs[0].option_type.toUpperCase() : '' }</span> - <span className='text-amber-600'>${ entry.legs && entry.legs[0] && entry.legs[0].strike_price ? Number(entry.legs[0].strike_price).toFixed(2).toLocaleString() : '-'}</span><span className='mx-1 text-sky-400 bg-sky-950 rounded px-1'>Expires: { entry.legs && entry.legs[0] && entry.legs[0].expiration_date ? entry.legs[0].expiration_date : '' }</span><br/>
										<span className='w-full flex flex-row items-center justify-start'>
											<span className='flex flex-row items-center justify-start'>
												{entry.quantity ? (Number(entry.quantity)).toLocaleString() : '-'} @ ${entry.price ? (Number(entry.price).toFixed(2)).toLocaleString() : '-'}<span className='mx-1 text-rose-400 bg-rose-950 rounded px-1'>Awaiting Fill: {entry.pending_quantity && entry.pending_quantity ? `${(Number(entry.pending_quantity)).toLocaleString()}` : '0'}</span>
											</span>
											<span className='flex flex-row flex-grow items-center justify-end'>
												&nbsp;
											</span>
										</span>
										<span className='text-emerald-400 bg-emerald-950 rounded px-1'>Expected: ${ entry.estimated_total_net_amount ? entry.estimated_total_net_amount : '-'}</span>
									</td>
									<td className="p-1 font-light inline-flex w-full h-full items-end justify-center flex-col gap-1">
										<button onClick={() => { cancelOrder(entry); }} className='w-[120px] text-red-300 bg-red-700 px-1 py-0.5 rounded h-full flex items-center justify-center'>Cancel</button>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			: <></>}

		</div>
  );
};

export default React.memo(MAIN, dontRerender);